var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
let LogbookCleaningLoading = class LogbookCleaningLoading extends LitElement {
    static get is() {
        return 'logbook-cleaning-loading';
    }
    static get styles() {
        return css `
            .loader {
                width: 60px;
                aspect-ratio: 2;
                --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
                background:
                    var(--_g) 0% 50%,
                    var(--_g) 50% 50%,
                    var(--_g) 100% 50%;
                background-size: calc(100% / 3) 50%;
                animation: l3 1s infinite linear;
            }

            @keyframes l3 {
                20% {
                    background-position:
                        0% 0%,
                        50% 50%,
                        100% 50%;
                }
                40% {
                    background-position:
                        0% 100%,
                        50% 0%,
                        100% 50%;
                }
                60% {
                    background-position:
                        0% 50%,
                        50% 100%,
                        100% 0%;
                }
                80% {
                    background-position:
                        0% 50%,
                        50% 50%,
                        100% 100%;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `<div class="loader"></div>`;
    }
};
LogbookCleaningLoading._deps = [];
LogbookCleaningLoading = __decorate([
    customElement(LogbookCleaningLoading.is)
], LogbookCleaningLoading);
export { LogbookCleaningLoading };
