var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { router } from '../QRPortalRouter';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import moment from 'moment';
import { QrPortalAPI } from 'qrportal-api/cerum/qrportal/qr_portal/QrPortalAPI';
import { isErrorHTTP, isSuccessHTTP } from 'qrportal-api/APIRestClient';
import { LogbookCleaningLoading } from '../components/LogbookCleaningLoading';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { DocumentViewer } from 'common-uicomponents/components/DocumentViewer';
let QRPortalResourceCleaningView = class QRPortalResourceCleaningView extends LitElement {
    constructor() {
        super(...arguments);
        this.cleaningDocuments = [];
        this.isLoading = false;
    }
    static get is() {
        return 'qr-portal-resource-cleaning-view';
    }
    static get styles() {
        return css `
            .container {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .previous .date {
                font-size: 180%;
            }

            .next {
                margin-top: 40px;
            }

            .next .date {
                font-size: 150%;
            }

            .missing {
                font-size: 150%;
                margin: 60px 0;
            }

            .documents-container {
                display: grid;
                flex-direction: row;
                gap: 6px;
                grid-template-areas: ' . . ';
            }

            .document-box {
                background-color: #f0f0f0;
                border-radius: 6px;
                font-size: 14px;
                box-shadow:
                    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
                    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                width: 150px;
                overflow: hidden;
                height: 250px;
                justify-content: space-between;
                background-color: white;
            }

            img {
                height: 100%;
                object-fit: cover;
            }

            .document-text {
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 6px;
                white-space: nowrap;
            }
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        this.hash = router.location.params.hash;
    }
    /** @override */ render() {
        return html `<div class="container">
            ${this.logbookOverview ? this._renderLogbookOverview(this.logbookOverview) : this._renderEmpty()}
        </div>`;
    }
    _renderLogbookOverview(logbookOverview) {
        var _a;
        return html `<div class="container">
            <h1>${logbookOverview.resource.name}</h1>

            ${logbookOverview.last
            ? html `<div class="previous">
                      <div>Sist rengjort</div>
                      <div class="date">
                          ${moment(logbookOverview.last.from)
                .locale('nb-no')
                .format('D. MMMM YYYY [kl.] HH:MM')}
                      </div>
                      <div>
                          av
                          ${logbookOverview.provider.name}${((_a = logbookOverview.last.operator) === null || _a === void 0 ? void 0 : _a.first_name)
                ? '/ ' + logbookOverview.last.operator.first_name
                : ''}
                      </div>
                  </div>`
            : ''}
            ${logbookOverview.next
            ? html `<div class="next">
                      <div>Neste rengjøring</div>
                      <div class="date">
                          ${moment(logbookOverview.next.from).locale('nb-no').format('D. MMMM YYYY')}
                      </div>
                  </div>`
            : ''}
            ${this.cleaningDocuments.length
            ? html `<h2 style="margin-top: 3rem">Dokumenter</h2>
                      <div class="documents-container">
                          ${this.cleaningDocuments.map((document) => {
                var _a;
                return html `<div
                                  class="document-box"
                                  @click="${() => this._onDocumentClick(document)}"
                              >
                                  <img src="${document.src}" />
                                  <span class="document-text">${(_a = document.metadata) === null || _a === void 0 ? void 0 : _a.title}</span>
                              </div>`;
            })}
                      </div>`
            : ''}
        </div>`;
    }
    _onDocumentClick(document) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if (!this.hash) {
                return;
            }
            OverlayWindow.asyncInject({
                title: (_b = (_a = document.metadata) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '',
                componentTagName: DocumentViewer.is,
                attributes: {
                    documentUrl: yield this._loadDocument(document.node_id),
                    documentMetadata: JSON.stringify({ title: (_d = (_c = document.metadata) === null || _c === void 0 ? void 0 : _c.title) !== null && _d !== void 0 ? _d : '' }),
                    showDownload: true,
                },
                fullscreen: true,
            });
        });
    }
    _renderEmpty() {
        if (this.isLoading) {
            return html `<logbook-cleaning-loading></logbook-cleaning-loading>`;
        }
        return html `<div class="missing">Mangler ressurs</div>`;
    }
    _hashObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            yield this._loadCleaningLogbook();
            yield this._loadCleaningDocuments();
            this.isLoading = false;
        });
    }
    _loadCleaningLogbook() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hash) {
                return;
            }
            const logbookOverviewResult = yield QrPortalAPI.getV1QrportalCleaningUsingHash(this.hash);
            if (isSuccessHTTP(logbookOverviewResult)) {
                this.logbookOverview = logbookOverviewResult;
            }
            else if (isErrorHTTP(logbookOverviewResult)) {
                SnackBar.pushError(logbookOverviewResult.error);
            }
        });
    }
    _loadCleaningDocuments() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hash) {
                return;
            }
            const logbookDocumentsResult = yield QrPortalAPI.getV1QrportalDocumentsUsingHash(this.hash);
            if (isErrorHTTP(logbookDocumentsResult)) {
                return;
            }
            for (const document of logbookDocumentsResult) {
                const loadDocumentPreviewResult = yield QrPortalAPI.getV1QrportalDocumentsUsingHashNodeid(this.hash, document.node_id, { preview: true });
                if (isErrorHTTP(loadDocumentPreviewResult)) {
                    SnackBar.pushError(loadDocumentPreviewResult.error);
                    return;
                }
                this.cleaningDocuments.push(Object.assign(Object.assign({}, document), { src: URL.createObjectURL(loadDocumentPreviewResult) }));
            }
        });
    }
    _loadDocument(nodeId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hash) {
                return;
            }
            const loadDocumentResult = yield QrPortalAPI.getV1QrportalDocumentsUsingHashNodeid(this.hash, nodeId, { preview: false });
            if (isErrorHTTP(loadDocumentResult)) {
                SnackBar.pushError(loadDocumentResult.error);
                return;
            }
            return URL.createObjectURL(loadDocumentResult);
        });
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'hash':
                    this._hashObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
QRPortalResourceCleaningView._deps = [LogbookCleaningLoading];
__decorate([
    state(),
    __metadata("design:type", String)
], QRPortalResourceCleaningView.prototype, "hash", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], QRPortalResourceCleaningView.prototype, "logbookOverview", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], QRPortalResourceCleaningView.prototype, "cleaningDocuments", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], QRPortalResourceCleaningView.prototype, "isLoading", void 0);
QRPortalResourceCleaningView = __decorate([
    customElement(QRPortalResourceCleaningView.is)
], QRPortalResourceCleaningView);
export { QRPortalResourceCleaningView };
