var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { router } from './QRPortalRouter';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
let QRPortal = class QRPortal extends LitElement {
    static get is() {
        return 'qr-portal';
    }
    get routerOutlet() {
        return this.shadowRoot && this.shadowRoot.getElementById('routerOutlet');
    }
    static get styles() {
        return css `
            :host {
                background-color: #cff4ff;
                width: 100%;
                height: fit-content;
                min-height: 100dvh;
                display: flex;
            }

            .wrapper {
                margin: 3rem auto 0px;
                width: 800px;
                background-color: whitesmoke;
                padding: 1rem;
                border-radius: 6px;
                box-shadow:
                    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
                    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                display: flex;
                flex-direction: column;
                height: fit-content;
            }

            .wrapper img {
                height: 60px;
            }

            footer {
                margin-top: 20px;
                text-align: right;
            }

            @media screen and (max-width: 1200px) {
                .wrapper {
                    width: 600px;
                }
            }

            @media screen and (max-width: 1024px) {
                .wrapper {
                    width: 500px;
                }
            }

            @media screen and (max-width: 768px) {
                .wrapper {
                    width: 400px;
                }
            }

            @media screen and (max-width: 480px) {
                .wrapper {
                    width: 300px;
                }

                header,
                footer {
                    text-align: center;
                }
            }
        `;
    }
    constructor() {
        super();
        this.updateComplete.then(() => __awaiter(this, void 0, void 0, function* () {
            router.setOutlet(this.routerOutlet);
        }));
    }
    /** @override */ render() {
        return html ` <div class="wrapper">
            <header>
                <img src="images/logo.svg" />
            </header>
            <main id="routerOutlet"></main>
            <overlay-window></overlay-window>
            <snack-bar></snack-bar>

            <footer>Løsningen er levert av <a href="https://cerum.no">Cerum AS</a></footer>
        </div>`;
    }
};
QRPortal._deps = [OverlayWindow, SnackBar];
QRPortal = __decorate([
    customElement(QRPortal.is),
    __metadata("design:paramtypes", [])
], QRPortal);
export { QRPortal };
