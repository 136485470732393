/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { PortalLogbookOverview } from '../../../schemas/PortalLogbookOverview';
import { SafiDocument } from '../../../schemas/SafiDocument';
export class QrPortalAPI extends APIRestClient {
    /**
     * Get cleaning Resource
     * Implementert hovedsakelig i *ewRestResourceQrPortalResources*::*getCleaningResource*
     */
    static getV1QrportalCleaningUsingHash(hash) {
        return this._get('/v1/qrportal/cleaning/{hash}', { hash }, null, null, PortalLogbookOverview.classOf(PortalLogbookOverview, new PortalLogbookOverview()), [], 'json');
    }
    /**
     * Get qr documents
     * Implementert hovedsakelig i *ewRestResourceQrPortalResourceDocuments*::*getResourceDocuments*
     */
    static getV1QrportalDocumentsUsingHash(hash) {
        return this._get('/v1/qrportal/{hash}/documents', { hash }, null, null, SafiDocument.classOf(SafiDocument, new SafiDocument()).arrayOf(), [], 'json');
    }
    /**
     * Get document stream
     * Implementert hovedsakelig i *ewRestResourceQrPortalResourceDocuments*::*getResourceDocument*
     */
    static getV1QrportalDocumentsUsingHashNodeid(hash, nodeID, __QUERY) {
        return this._get('/v1/qrportal/{hash}/documents/{nodeID}', { hash, nodeID }, __QUERY, null, APIRestClient.Native.Blob(), [], 'blob');
    }
}
